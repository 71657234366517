import React from 'react';

function Home() {

  return (
    <div>
      <h1>Home Heading</h1>
    </div>
  );
}

export default Home